import React from "react";
import Layout from "../components/layout";
import { getRoomById } from "../services/roomService";
import Soba from "../components/soba";
import { graphql } from "gatsby";
import { useIntl } from "gatsby-plugin-intl";
import SEO from "../components/seo";

const Soba3 = ({ data }) => {
  const room = getRoomById(3)[0];
  const slike = data.allFile.edges;
  const intl = useIntl();
  return (
    <Layout>
      <SEO
        lang={intl.locale}
        title={intl.formatMessage({ id: "jedinica3.naslov" })}
      />
      <Soba room={room} slike={slike} />
    </Layout>
  );
};

export default Soba3;

export const query = graphql`
  query {
    allFile(filter: { relativeDirectory: { eq: "jedinica-3" } }) {
      edges {
        node {
          childImageSharp {
            fluid(sizes: "(min-width: 10px) 2000px, 2000px", maxWidth: 2000) {
              ...GatsbyImageSharpFluid
            }
          }
          id
        }
      }
    }
  }
`;
